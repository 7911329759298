<template>
  <transition
    v-on:leave="globeLeave"
    v-on:enter="globeEnter"
  >
    <component :is="currentView" @close="currentView = 'base-view'"/>
  </transition>
</template>

<script>
import BaseView from '@/views/BaseView'
import SplashView from '@/views/SplashView'

export default {
  name: 'App',
  components: {
    'base-view': BaseView,
    'splash-view': SplashView,
  },
  data () {
    return {
      currentView: 'splash-view'
    }
  },
  methods: {
    globeEnter(el, done) {
      this.gsap.timeline()
        .to(el, {
          onComplete: done,
        })
    },
    globeLeave(el, done) {
      this.gsap.timeline()
        .to(el, {
          duration: 4,
        })
        .to(el, {
          onComplete: done,
        })
    }
  },
  mounted () {
    
  }
}
</script>

<style lang="scss">
  @import "assets/css/main";
</style>
