<template>
  <div class="splash">
    <div class="marquee" ref="marq">
      <div class="a" ref="marqA">
        <BrickWall />
      </div>
      <div class="b" ref="marqB">
        <BrickWall />
      </div>
    </div>
    <div class="overlay a" ref="opA"></div>
    <div class="overlay b" ref="opB"></div>
    <div class="first">
      <img src="@/assets/i/logotype-color.svg" ref="logotype" alt="xe" />
    </div>
  </div>
</template>

<script>
import BrickWall from '@/components/BrickWall.vue'

export default {
  name: 'SplashView',
  components: {
    BrickWall
  },
  methods: {
    close() {
      this.$emit('close')
    }
  },
  mounted() {

    let ScreenWidth = window.innerWidth
    let MarqWidth = this.$refs.marqA.offsetWidth
    let Transition = ScreenWidth - MarqWidth

    let vm = this

    this.gsap.timeline()
      .to(this.$refs.marqA, {
        x: - Transition / 8,
        duration: 2,
        ease: 'none',
      })
      .to(this.$refs.marqB, {
        x: Transition / 8,
        duration: 2,
        ease: 'none',
      }, 0)
      .from(this.$refs.opA, {
        scaleX: 0,
        transformOrigin: 'left top',
        duration: 0.8,
        ease: 'expo.out',
      }, 1.2)
      .from(this.$refs.opB, {
        scaleX: 0,
        transformOrigin: 'right top',
        duration: 0.8,
        ease: 'expo.out',
      }, '-=0.8')
      .from(this.$refs.logotype, {
        y: '150%',
        duration: 0.6,
        ease: 'expo.out',
      }, '-=0.8')
      .set(this.$refs.marq, {
        autoAlpha: 0,
      })

      .to(this.$el, {
        onComplete: function() {
          vm.close()
        }
      })

      .to(this.$refs.logotype, {
        y: '-150%',
        duration: 0.8,
        ease: 'expo.in',
      }, '+=0.2')
      .to(this.$refs.opA, {
        scaleY: 0,
         transformOrigin: 'left top',
        duration: 0.8,
        ease: 'expo.in',
      }, '-=0.8')
      .to(this.$refs.opB, {
        scaleY: 0,
         transformOrigin: 'right top',
        duration: 0.8,
        ease: 'expo.in',
      }, '-=0.8')
  }
}
</script>
