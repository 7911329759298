import { createApp } from 'vue'
import App from './App.vue'
import { createStore } from 'vuex'
import { gsap } from "gsap"

// Create a new store instance.
const store = createStore({
  state: {},
  mutations: {},
  actions: {}
})
const app = createApp(App)

app.use(store)

app.mixin({
  created: function () {
    this.gsap = gsap
  },
})

app.config.productionTip = false







app.mount('#xe')
