<template>
  <div class="tou-pp">
    <div class="overlay" @click="$emit('close-TOU')"></div>

    <div class="tabs">
      <div class="close" @click="$emit('close-TOU')">Close</div>
      <div class="head">
        <div class="tou" :class="{active : tou}" @click="touShow()">
          <h6>Terms of use</h6>
        </div>
        <div class="pp" :class="{active : pp}" @click="ppShow()">
          <h6>Privacy policy</h6>
        </div>
      </div>
      <div class="body">
        <div class="tou" v-if="tou">
          <TOU />
        </div>
        <div class="pp" v-if="pp">
          <PP />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TOU from '@/components/TOU.vue'
import PP from '@/components/PP.vue'

export default {
  name: 'TOUandPP',
  components: {
    TOU,
    PP,
  },
  data () {
    return {
      tou: true,
      pp: false,
    }
  },
  methods: {
    touShow () {
      this.tou = true
      this.pp = false
    },
    ppShow () {
      this.tou = false
      this.pp = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/var';

.tou-pp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: $black;
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $black;
    opacity: 0;
  }
  .tabs {
    position: relative;
    width: 100%;
    height: 80%;
    background: $white;
    display: flex;
    flex-direction: column;

    .close {
      color: $white;
      position: absolute;
      left: 50%;
      bottom: 100%;
      transform: translate(-50%,-100%) rotate(0);
      transform-origin: left bottom;
      cursor: pointer;
    }
    .close:hover {
      text-decoration: underline;
    }
    
    .head {
      display: flex;
      border-bottom: 1px solid $gray;

      div {
        text-align: center;
        padding: 0.8rem 0 0.555rem;
        width: 50%;
        cursor: pointer;
        border-bottom: 2px solid $white;

        h6 {
          color: $black;
        }
      }
      div.active {
        border-bottom: 2px solid $main;

        h6 {
          color: $main;
        }
      }
    }
    .body {
      overflow-x: hidden;
      overflow-y: scroll;
      height: 100%;

      .tou,
      .pp {
        padding: 4rem 20% 8rem 10%;
      }
    }
  }
}

@media (min-width: 768px) {
  .tou-pp {
    .tabs {
      width: 50%;
      height: 90%;

      .close {
        left: 100%;
        top: 50%;
        bottom: auto;
        right: auto;
        transform: translate(25%,-50%) rotate(90deg);
        transform-origin: left bottom;
      }
    }
  }
}
</style>

