<template>
  <div class="inner">
    <div class="brick"><img src="@/assets/i/logotype-gray.svg" alt="xe" /></div>
    <div class="brick"><img src="@/assets/i/logotype-gray.svg" alt="xe" /></div>
    <div class="brick"><img src="@/assets/i/logotype-gray.svg" alt="xe" /></div>
    <div class="brick"><img src="@/assets/i/logotype-gray.svg" alt="xe" /></div>
    <div class="brick"><img src="@/assets/i/logotype-gray.svg" alt="xe" /></div>
    <div class="brick"><img src="@/assets/i/logotype-gray.svg" alt="xe" /></div>
    <div class="brick"><img src="@/assets/i/logotype-gray.svg" alt="xe" /></div>
    <div class="brick"><img src="@/assets/i/logotype-gray.svg" alt="xe" /></div>
    <div class="brick"><img src="@/assets/i/logotype-gray.svg" alt="xe" /></div>
    <div class="brick"><img src="@/assets/i/logotype-gray.svg" alt="xe" /></div>
  </div>
</template>

<script>
export default {
  name: 'BrickWall',
}
</script>
