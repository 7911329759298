<template>
  <div class="text-content">
    <h3>Privacy policy</h3>
    <div class="space"></div>
    <div class="space"></div>
    
    <p>Your Privacy is our top priority, so we provide this Privacy Policy to describe our practices and procedures as to how Xeworks collects, uses and discloses information, and what rights you have with respect to the personal information. Please read this Policy carefully.</p>
    <p>This Policy is incorporated into, and subject to, Xeworks Terms & Conditions. All terms undefined in this Policy should be understood and have the same meaning as in Terms & Conditions. If you do not accept this Privacy Policy, please do not use this website.</p>
    <p>We reserve the right to revise this Privacy Policy from time to time. The most current version of the Privacy Policy will be available on this page, so you can be always aware of the up-to-date information. For changes to this Privacy Policy that may be materially less restrictive on our collection, usage and/or disclosure of personal information you have already provided to us, we will attempt to obtain your consent before implementing the changes.</p>
    <p>For all other changes, it is at your sole responsibility to review the terms of this Policy from time to time to ensure you agree with it and any applicable amendments. Your continued use of the Website constitutes your acceptance of the updated Privacy Policy.</p>
    <p>If you have any questions regarding this Policy, please email us at: team@xe.works.</p>
    
    <div class="space"></div>
    <h4>What do we do?</h4>
    <p>Xeworks (“we”, “us”) is an advertising platform, based on openRTB protocol, using machine learning algorithms for maximizing advertising efficiency. In other words, we facilitate the purchase of media advertising inventory in real-time binding (RTB) auctions, providing a specific software tool for advertisers (“Demand Side Platform”, “DSP”) and publishers (“Supply Side Platform”, “SSP”) to be connected and provide end users who interact with the SSP (“End User”) with the most relevant advertising materials.</p>
    
    <div class="space"></div>
    <h4>What is personal information?</h4>
    <p>Personal information, or Personal Data, means any information relating to the you and that alone or in cumulation with other pieces of information gives the opportunity to the person that collects and processes such information to identify you as a person. It can be your name, identification number, location data, or information related to physical, physiological, genetic, mental, economic, cultural or social life.</p>
    <p>Processing means any operation or set of operations which is performed on personal data, for example, collection, recording, organization, structuring, storage, use, disclosure by any means (whether automated or not) and so on.</p>
    
    <div class="space"></div>
    <h4>What personal information is processed?</h4>
    <p>The exact scope of information that we process depends on the purpose and manner with which you visit the Website, interact with us and/or our Services. This Section describes all types of information that we collect and/or process from the Customers and End Users.</p>

    <div class="space"></div>
    <h4>Information processed from the customers</h4> 
    <p>Personally Identifiable Information. We collect and further process personally identifiable information when you fill a request form on the Website, including, but not limited to your first name, last name, email, company name. This is information that allows to identify you as a specific individual. We do so only at your request and the contractual basis applies to such processing. Without these data we will not be able to provide you with the opportunity of possessing the account on the Website.</p>
    <p>Non-Personally Identifiable Information. We may also process information that does not personally identify you. We may collect such data only if you give us a consent to do so. We may collect the following non-personally identifiable information which may include:</p>

    <ul>
      <li>
        <div>Log File Information</div>
        <p>The log files occur on the website and collect data including your IP address, browser type, operating system, Internet Service Provider, network carrier information.</p>
      </li>
      <li>
        <div>Mobile Device Information</div>
        <p>We may also collect the following mobile specific information: device type, make and model, operating system, mobile advertising identifiers, operating system device identifier, the GPS location of your device (if opted-in).</p>
      </li>
      <li>
        <div>Non-Precise Geographic Location</div>
        <p> device may be capable of sharing your geographic location with the apps or web pages you visit using the device. It might be possible through GPS coordinates and/or information about Wi-Fi networks in your vicinity. We collect information of your non-precise geographic information, limited to the country and city of the device location.</p>
      </li>
      <li>
        <div>Analytics</div>
        <p>We may also collect information, provided by Google Analytics, such as your IP host address, Internet service provider, browser type, operating system and language, referring and exit pages and URLs, date and time, amount of time spent on particular pages, number of links you click, search terms, and other data. This information is collected automatically and anonymized by Google Analytics. You can also opt-out from being tracked by <a href="http://tools.google.com/dlpage/gaoptout?hl=en" target="_blank">Google Analytics</a></p>
      </li>
      <li>
        <div>Cookies and Pixel Tags</div>
        <p>These are the small files stored on a User’s internet enabled device (computer, mobile phone etc.) to understand the User’s experience on the website. We use cookies to customize our website to your needs, display personalized content, store the User’s preferences, and measure the promotional effectiveness. We also use pixel tags, some small blocks of code on a webpage that allows us to set, read and modify cookies.</p>
      </li>
    </ul>
    <p>You may also refuse the use of cookies by selecting the relevant settings on your browser. Please note that if you opt-out cookies once, you may need to renew your choice, when using different device or Internet browser.</p>
    <p>Special Categories of Personal Information. We do not collect any personal data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, and the processing of genetic data, biometric data for the purpose of uniquely identifying a natural person, data concerning health or data concerning a natural person’s sex life or sexual orientation.</p>

    <div class="space"></div>
    <h4>Information processed from the third parties</h4>
    <p>We may also process information, provided by our partners (DSP, SSP) with the use of their own software and/or services and collected from the End Users. In this respect, we place our partners under the commitment to comply with all applicable laws in all applicable jurisdictions, in particular obtain the End Users’ explicit consent to collect information that does not personally identify the End Users.</p>
    <p>Such non-identifiable information may include IP address, browser type, operating system, Internet Service Provider, network carrier information, device type, make and model, operating system, mobile advertising identifiers, operating system device identifier, the GPS location of the device (if opted-in), non-precise geographic information, limited to the country and city of the device location.</p>
    <p>We may combine the information we receive from third parties with the information we directly collect from you and use such information accordingly to this Privacy Policy and requirements under applicable EU data protection and privacy laws.</p>

    <div class="space"></div>
    <h4>What is the legal basis for personal information processing?</h4>

    <p>Your Personal Information is processed based on the following legal basis:</p>

    <ul>
      <li>the processing is based on your explicit consent to the processing of your personal data;</li>
      <li>the processing is necessary to perform a contract to which you are a party (e.g. Terms and Conditions which constitutes a legally binding agreement between you and Xeworks as regards to the provision of our Services);</li>
      <li>the processing is necessary for compliance with our legal obligations.</li>
    </ul>
    <p>Prior to any processing of your Personal Information, we will inform you about the applied legal basis and other relevant details, unless we are not allowed to so according to the applicable legislation.</p>

    <div class="space"></div>
    <h4>How do we use the information?</h4>
    <p>We may use the information collected in the following ways:</p>
    <ul>
      <li>To provide ad exchange services;</li>
      <li>To administer and protect our website;</li>
      <li>To assist the enforcement of our Terms & Conditions;</li>
      <li>To carry out business activity;</li>
      <li>To develop new services, features and content;</li>
      <li>To customize the website due to the user’s interests, location, device;</li>
      <li>To deliver the relevant website content to you and measure the effectiveness of promotion;</li>
      <li>To prevent providing the same information to the same user;</li>
      <li>To use data analytics to monitor and improve our services and technology, marketing, the User experiences;</li>
      <li>To prevent potential illegal activity and respond to legal requests.</li>
    </ul>
    <p>Xeworks commits to use the information collected solely for the purposes described in this Privacy Policy. If we decide to modify and/or add any purpose, we will inform you about all changes and ask for your consent prior to the application of any amendments.</p>

    <div class="space"></div>
    <h4>To whom we disclose information?</h4>
    <p>Except as otherwise provided in this Policy, permitted or required under law, we will not intentionally disclose any personal information about you as a specific individual to third parties without your prior consent.</p>
    <p>As we engage third parties (DSP, SSP, other partners) to provide services to you, we may share with them information that is reasonably necessary to perform their functions and our agreements with them require to comply with applicable data protection laws.</p>
    <p>We may disclose certain non-personally identifiable information to third parties to carry out business activity, comply with the reporting obligations, to understand the End Users’ preferences, habits, usage patterns, to provide the relevant content and services via the DSP’s website.</p>
    <p>We may also disclose personal data or other information: (i) in connection with a government inquiry, dispute or other legal process or request; (ii) when we, in good faith, believe that such disclosure is necessary to detect, prevent any fraud, investigate potential violations of our Terms & Conditions; (iii) to provide the security of the website, protect our property or legal rights, enforce our agreements or ensure other security measures.</p>

    <div class="space"></div>
    <h4>How do we transfer, store and process information?</h4>
    <p>Your Personal Information may be shared and/or transferred only where necessary and to the minimum extent required. Where applicable, we may use <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en" target="_blank">Standard Contractual Clauses</a> approved by the European Commission to secure transmission of the Personal Information.</p>
    <p>Please note that we will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission.</p>
    <p>We may transfer, store and process personal information on the servers located in the United States. With respect to the personal information transferred to the United States, Xeworks complies with <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/eu-us-data-transfers_en" target="_blank">EU-US Privacy Shield Framework</a>.</p>

    <div class="space"></div>
    <h4>How we protect information?</h4>
    <p>We maintain the appropriate administrative, technical and physical safeguards to any accidental or unlawful destruction, loss, unauthorized alteration, unauthorized disclosure or access, misuse, and any other form of breach in regard to your personal information. These measures provide sound industry standard security.</p>
    <p>Although we make efforts to protect your privacy, Xeworks cannot guarantee a 100% security of any information you transmit to us.</p>
    <p>If you believe your personal information has been compromised, please contact us at team@xe.works</p>
    <p>If we find out of any security systems breach, we will inform you and the relevant authorities of the occurrence of the breach accordingly to the applicable law.</p>

    <div class="space"></div>
    <h4>How long do we retain personal information?</h4>

    <p>We retain personal information collected from you as long as we need it to fulfil the purposes for which it was initially collected, unless otherwise is required by law. We will retain and use the information collected with regard to the applicable laws’ requirements, as follows:</p>

    <ul>
      <li>accounting source documents is retained for the period of 10 years according to the Lithuanian accounting and taxation laws;</li>
    
    </ul>
    <p>We may retain your personal information longer, if: (i) it is required under certain laws or to comply with law enforcement or other requests (e.g. those related to corporate governance, taxation, anti-money laundering and financial reporting legislation); (ii) there is a reasonable suspicion of fraudulent, unauthorized, or illegal activity; (iii) it is necessary to be used in the investigation, dispute/complaint resolution.</p>
    <p>In some circumstances, we may delete your personal data on your request, as described below in this Policy. We may also anonymize your personal information (so it will be no longer associated with you) for analytical or statistical purposes.</p>

    <div class="space"></div>
    <h4>What are your rights?</h4>
    <p>Under the GDPR, Users from EU and EEA are subject to a number of rights regarding the collection and processing of their personal information, including:</p>
    <ul>
      <li>Right to be informed. You are entitled to receive concise, transparent, intelligible, easily accessible information regarding the purposes for processing of personal data, retention periods for storing of the respective personal data, persona data sharing particularities and other.</li>
      <li>Right to access. You are able to ask us how and in which way Xeworks is using your personal data as well as to check the lawfulness of the processing.</li>
      <li>Right to rectification. You may inquire us to rectify inaccurate, incomplete and/or misleading data to any piece of personal information, as well as provide us with the accurate, complete and/or updated data.</li>
      <li>Right to erasure. This means that you may request us to erase the information that is no longer necessary for the purposes for which it was collected, as well as no legal grounds for processing exists.</li>
      <li>Right to restrict processing. If you have a particular reason to restrict processing, either we process inaccurate data, or there is no need to continue processing your data, you may request us to restrict such processing.</li>
      <li>Right to withdraw consent. You may ask us to withdraw your consent for the collection and processing personal information at any time. Exercising this right will not affect the lawfulness of processing based on consent before its withdrawal.</li>
    </ul>
    <p>If you have any concerns regarding your rights, please do not hesitate to contact Xeworks Data Protection Team at team@xe.works</p>
    <p>We will answer your request as soon as possible and without undue delay but no longer than within one month from the date of its receipt.</p>
    <p>You also have the possibility to file a complaint to the supervisory authority that is empowered to process such complaints in your country.</p>

    <div class="space"></div>
    <h4>How do we protect privacy of children?</h4>
    <p>This website is not intended for children under the age of 18 and we do not knowingly or intentionally collect any information relating to children.</p>

    <div class="space"></div>
    <h4>Contact us</h4>
    <p>If you have any concerns regarding your rights, please do not hesitate to contact Xeworks Data Protection Team at team@xe.works</p>

  </div>
</template>

<script>
export default {
  name: 'PP',
}
</script>

<style lang="scss" scoped>
@import '../assets/css/var';

h4 {
  margin-bottom: 2rem;
  border-bottom: 1px solid $gray;
}
p {
  margin-bottom: 1.4rem;
}
.space {
  height: 2rem;
}
.sep {
  height: 1px;
  background: $gray;
}

ul {
  margin-top: 2rem;
  padding-left: 1.5rem;
  margin-bottom: 4rem;

  li {
    margin-bottom: 2rem;
    position: relative;

    &::before {
      content: "";
      background: $main;
      display: block; 
      width: 0.665em;
      height: 0.665rem;
      border-radius: 50%;
      position: absolute;
      left: -1.5rem;
      top: 0.35rem;
    }
    div {
      color: $main;
      margin-bottom: 0.5rem;
    }
    p {}
  }
}

</style>
