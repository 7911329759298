<template>
  <div class="cookies">
    <figure>
      <img src="@/assets/i/cookie.svg" alt="xe">
    </figure>
    <div class="tt small">We use cookies in order to ensure your best experience on our website. By pressing OK you consent to our use of cookies on this device in accordance with our <a href="Cookie-Policy_Xe.works.pdf" target="_blank">Cookie policy</a>, unless you have disabled them.</div>
    <div class="bttn small" @click="$emit('close-cookie')">
      <div class="">I understand</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookiesModal',
}
</script>

<style lang="scss" scoped>
@import '../assets/css/var';

h4 {
  margin-bottom: 2rem;
  border-bottom: 1px solid $gray;
}
p {
  margin-bottom: 1.4rem;
}
.space {
  height: 2rem;
}
.sep {
  height: 1px;
  background: $gray;
}

ul {
  margin-top: 2rem;
  padding-left: 1.5rem;
  margin-bottom: 4rem;

  li {
    margin-bottom: 2rem;
    position: relative;

    &::before {
      content: "";
      background: $main;
      display: block; 
      width: 0.665em;
      height: 0.665rem;
      border-radius: 50%;
      position: absolute;
      left: -1.5rem;
      top: 0.35rem;
    }
    div {
      color: $main;
      margin-bottom: 0.5rem;
    }
    p {}
  }
}

</style>
