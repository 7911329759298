<template>
  <div class="main" data-scroll-container>

    <Page id="Container" data-scroll-section @open-modal="openModal()" />

    <transition v-on:enter="modalEnter" v-on:leave="modalLeave">
      <Modal id="Modal" v-if="modal" @close-modal="closeModal()" />
    </transition>

    <CookiesModal id="CookiesModal" v-if="cookies" @close-cookie="closeCookie()" />
    
  </div>
</template>

<script>
import Page from '@/components/Page.vue'
import Modal from '@/components/Modal.vue'
import CookiesModal from '@/components/CookiesModal.vue'

import LocomotiveScroll from 'locomotive-scroll'
import imagesLoaded from 'imagesloaded'

export default {
  name: 'BaseView',
  components: {
    Page,
    Modal,
    CookiesModal
  },
  data () {
    return {
      modal: false,
      cookies: true,
    }
  },
  methods: {
    openModal() {
      this.modal = true
    },
    closeModal() {
      this.modal = false
    },

    modalLeave(el, done) {

      this.lmS.start()

      this.gsap.timeline()
        .set(el, {
          css: {
            'pointer-events': 'none'
          }
        })
        .to(el, {
          autoAlpha: 0,
          scale: 0.9,
          duration: 0.4,
          ease: 'expo.out',
        })
        .to(el, {
          onComplete: done
        })
    },

    modalEnter(el) {
      this.lmS.stop()

      this.gsap.set(el, {
        css: {
          'pointer-events': 'all'
        }
      })
    },

    closeCookie () {
      localStorage.cookiesShown = 'true'
      this.cookies = false
    }
  },
  mounted () {
    if (localStorage.cookiesShown === 'true') {
      this.cookies = false
    }

    this.lmS = new LocomotiveScroll({
        el: document.querySelector('[data-scroll-container]'),
        smooth: true,
        smoothMobile: false,
        initPosition: {x: 0, y: 0},
        offset: ["0%", 0],
      })
      imagesLoaded('[data-scroll-container]', () => {
        this.lmS.update()
      })
  }
}
</script>
