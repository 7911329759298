<template>
  <div class="repeat">
    <div class="t">Wouldn’t it be<br/>great to actually</div>
    <div class="h2">have all that?</div>
  </div>
</template>

<script>
export default {
  name: 'Marquee',
}
</script>
