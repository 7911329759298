<template>
  <div class="modal">
    <div class="overlay" ref="overlay"></div>
    <div class="modal-inner" :class="{'show-info' : mobileInfoclass, 'move-back' : toupp,}" ref="modal">

      <div class="modal-header">
        <div class="info-trigger" @click="mobileInfoclass = !mobileInfoclass">
          <div class="show">
            <div class="circle"></div>
            <img src="@/assets/i/modal/i.svg" alt="xe">
          </div>
          <div class="hide">
            <div class="circle"></div>
            <img src="@/assets/i/arrow-right.svg" alt="xe">
          </div>
        </div>

        <div class="close" @click="$emit('close-modal')">
          <div class="circle"></div>
          <img src="@/assets/i/modal/close-b.svg" alt="xe">
        </div>
      </div>

      <div class="content">

        <div class="form-content" ref="fCont">
          <div class="fc-inner">
            <div class="modal-title">
              <div class="logo-black">
                <div class="circle a" ref="crclA">
                  <img class="" src="@/assets/i/modal/logo-circle-b.svg" alt="xe" />
                </div>
                <div class="sign" ref="sign">
                  <img class="" src="@/assets/i/modal/logo-sign-b.svg" alt="xe" />
                </div>
                <img class="r" ref="r" src="@/assets/i/modal/r-b.svg" alt="xe" />
              </div>
              <div class="h2">
                <div class="ofh">
                  <div>Request</div>
                </div>
                <div class="ofh">
                  <div>a demo</div>
                </div>
              </div>
              <div class="registration small">UAB XEWORKS, reg. #305700956,<br/>Švitrigailos g. 11K-109,<br/>Vilnius, Lithuania</div>
            </div>
            <div class="modal-contact">
              <div class="inner-top">
                <div class="email">
                  <div class="desktop">team@xe.works</div>
                  <a class="mobile" href="mailto:team@xe.works">team@xe.works</a>
                </div>
                <div class="socials">
                  &nbsp;
                  <a href="https://www.facebook.com/Xe.works/" target="_blank">
                    <img class="" src="@/assets/i/modal/fb-b.svg" alt="xe" />
                  </a>
                  <a href="https://www.linkedin.com/company/xe-works/" target="_blank">
                    <img class="" src="@/assets/i/modal/in-b.svg" alt="xe" />
                  </a>
                </div>
              </div>
              <div class="inner-bot">

                <form class="form" ref="form" @submit.prevent="sendEmail">
                  <div class="fields">

                      <div class="field email" :class="{errored: emailError || emailCorError, active: email}">

                        <div class="label h3">
                          <label for="email-field" class="small">Email</label>

                          <transition v-on:leave="errorLeave" v-on:enter="errorEnter">
                            <div class="error small" v-if="emailError">Your email please</div>
                          </transition>

                          <transition v-on:leave="errorLeave" v-on:enter="errorEnter">
                            <div class="error small" v-if="emailCorError">Something is wrong</div>
                          </transition>
                        </div>

                        <div class="input" :class="{active: email}">
                          <input id="email-field" class="h3" name="email" v-model="email" @keyup="checkEmail" @input="autosizeInput()" ref="email" />
                          <span class="placeholder h3" :class="{hide: email}" >@</span>
                        </div>

                      </div>
                
                      <div class="field message" :class="{errored: messageError, active: message}">

                        <div class="label h3">
                          <label for="message-field" class="small">Message</label>

                          <transition v-on:leave="errorLeave" v-on:enter="errorEnter">
                            <div class="error small" v-if="messageError">Write a message</div>
                          </transition>
                        </div>

                        <div class="input" :class="{active: message}">
                          <textarea id="message-field" class="h3" name="message" rows="1" v-model="message" @keyup="checkMessage" @input="autosizeTextarea()" ref="message" />
                          <span class="placeholder h3" :class="{hide: message}" >Tt</span>
                        </div>
        
                      </div>

                  </div>

                  <div class="chbxs">

                      <div class="chb a" :class="{errored: inputTermsError}">
                        <input type="checkbox" id="agreetoterms" v-model="termsCheck" @click="checkTerms" />
                        <label for="agreetoterms" class="small">I agree to the <a href="#" @click="toggleTOU()">Terms of use</a> and <a href="#" @click="toggleTOU()">Privacy policy</a>.</label>
                      </div>
                      <div class="chb b" :class="{errored: inputConsentError}">
                        <input type="checkbox" id="personaldata" v-model="consetCheck" @click="checkConset" />
                        <label for="personaldata" class="small">I consent to share my personal data with Xe.works for further.</label>
                      </div>
                  </div>

                  <button ref="submit" style="display:none;">Submit</button>

                </form>

              </div>
            </div>
          </div>
        </div>

        <div class="addit-content" ref="aCont">
          <div class="ac-inner">
            <div class="logo-black">
              <div class="circle a" ref="crclA">
                <img class="" src="@/assets/i/modal/logo-circle-b.svg" alt="xe" />
              </div>
              <div class="sign" ref="sign">
                <img class="" src="@/assets/i/modal/logo-sign-b.svg" alt="xe" />
              </div>
              <img class="r" ref="r" src="@/assets/i/modal/r-b.svg" alt="xe" />
            </div>
            
            <div class="t-row-a">
              <p>Spend wisely,<br/>earn effortlessly :)</p>
              <div class="registration small">UAB XEWORKS, reg. #305700956,<br/>Švitrigailos g. 11K-109,<br/>Vilnius, Lithuania</div>
            </div>
            <div class="t-row-b">
              <p>We would like to collect your name, email, and company name to obtain a proper contact and provide further cooperation with you. Read more about the services we provide in <a href="#" @click="toggleTOU()">Terms of use</a> and how we collect, use, and disclose your personal data in <a href="#" @click="toggleTOU()">Privacy policy</a>.</p>
            </div>
            <div class="t-row-c">
              <p>Please note that you can withdraw your consent any time by contacting us.</p>
            </div>
          </div>
        </div>

      </div>
      <div class="aside">
        <div class="message-sent" v-if="itsSent"><h2>Message sent</h2></div>

        <div class="a-inner" ref="aside">

          <div class="cc">
            <div class="close" @click="$emit('close-modal')">
              <div class="circle"></div>
              <img src="@/assets/i/modal/close.svg" alt="xe">
            </div>
          </div>
          <div class="form-bttn" @click="submit()" :class="[email && message ? disClass : 'disabled']">
            <div>Send</div>
          </div>

        </div>
      </div>
    </div>

    <transition v-on:enter="TOUEnter" v-on:leave="TOULeave">
      <TOUandPP v-if="toupp" @close-TOU="toggleTOU()"/>
    </transition>

    <div class="op a" ref="opA"></div>
    <div class="op b" ref="opB"></div>

    <div class="last" ref="last">
      <img src="@/assets/i/logotype-black.svg" ref="logotype" alt="xe" />
    </div>

  </div>
</template>

<script>
import TOUandPP from '@/components/TOUandPP.vue'

import emailjs from 'emailjs-com'

export default {
  name: 'Modal',
  components: {
    TOUandPP,
  },
  data () {
    return {
      email: '',
      message: '',

      emailError: false,
      emailCorError: false,

      messageError: false,

      inputTermsError: false,
      termsCheck: true,
      
      inputConsentError: false,
      consetCheck: true,

      errorClass: '',
      disClass: '',

      itsSent: false,

      mobileInfoclass: false,

      toupp: false,
    }
  },
  methods: {

    toggleTOU() {
      this.toupp = !this.toupp
    },
    
    autosizeInput() {
      let el = this.$refs.email

      if(el.value.length > 20) {
        el.classList.remove('h3')
        el.classList.add('reduce')
      }
      else {
        el.classList.remove('reduce')
        el.classList.add('h3')
      }
    },
    autosizeTextarea() {
      let el = this.$refs.message

      if(el.value.length > 40) {
        el.classList.remove('h3')
        el.classList.add('reduce')
      }
      else {
        el.classList.remove('reduce')
        el.classList.add('h3')
      }

      el.style.height = 'auto'
      el.style.height = (el.scrollHeight) + 'px'

    },
    
    submit() {
      this.$refs.submit.click()
    },

    messageSent() {
      this.itsSent = true
      let vm = this

      let query = window.matchMedia("(max-width: 768px)")
      let directionX
      let alphaIt

      if (query.matches) {
        directionX = "0"
        alphaIt = "0"
      } else {
        directionX = "100"
        alphaIt = "1"
      }

      this.gsap.timeline()
        .to(this.$refs.aside, {
          xPercent: directionX,
          autoAlpha: alphaIt,
          duration: 0.4,
          ease: 'expo.out',
        })
        .to(this.$refs.aside, {
          xPercent: 0,
          autoAlpha: 1,
          duration: 0.4,
          ease: 'expo.in',
        })
        .to(this.$refs.aside, {
          onComplete: function() {
            vm.itsSent = false
          }
        })
    },

    sendEmail(e) {

      if (!this.email) {
        this.emailError = true
      } else if (!this.validEmail(this.email)) {
        this.emailCorError = true
      }
      if (!this.message) {
        this.messageError = true
      }

      if (!this.termsCheck) {
        this.inputTermsError = true
      }
      if (!this.consetCheck) {
        this.inputConsentError = true
      }

      if (this.email && !this.emailCorError && this.message && !this.inputTermsError && !this.inputConsentError) {

        try {
          emailjs.sendForm('service_vz5z5zf', 'template_ra1xs09', e.target, 'user_GbJuwixukd6usNEedfP7L', {
            email: this.email,
            message: this.message
          })

        } catch(error) {
          alert('Error')
        }
        // Reset form field
        this.email = ''
        this.message = ''

        this.messageSent()

      }
    },

    validEmail (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    checkEmail () {
      if (this.email) {
        this.emailError = false
        this.emailCorError = false
      }
      else {
        this.emailError = true
        this.emailCorError = false
      }
    },
    checkMessage () {
      if (this.message) {
        this.messageError = false
      }
      else {
        this.messageError = true
      }
    },
    checkTerms () {
      if (!this.termsCheck) {
        this.inputTermsError = false
      }
    },
    checkConset () {
      if (!this.consetCheck) {
        this.inputConsentError = false
      }
    },

    errorLeave(el, done) {
      this.gsap.timeline()
        .to(el, {
          autoAlpha: 0,
          y: 10,
          duration: 0.4,
          ease: 'expo.out',
        })
        .to(el, {
          onComplete: done
        })
    },
    errorEnter(el, done) {
      this.gsap.timeline()
        .from(el, {
          autoAlpha: 0,
          y: 10,
          duration: 0.4,
          ease: 'expo.out',
        })
        .to(el, {
          onComplete: done
        })
    },

    //—————————

    TOUEnter(el, done) {
      this.gsap.timeline()
        .fromTo(el.querySelectorAll('.overlay'), {
          autoAlpha: 0,
        }, {
          autoAlpha: 0.8,
          duration: 0.8,
          ease: 'power0.out',
        })
        .fromTo(el.querySelectorAll('.tabs'), {
          autoAlpha: 0,
          scale: 0.9,
          yPercent: 25,
        }, {
          autoAlpha: 1,
          yPercent: 0,
          scale: 1,
          duration: 0.8,
          ease: 'expo.out',
        })
        .to(el, {
          duration: 0,
          onComplete: done
        })
    },
    TOULeave(el, done) {
      this.gsap.timeline()
        .fromTo(el.querySelectorAll('.tabs'), {
          autoAlpha: 0.8,
          yPercent: 0,
        }, {
          autoAlpha: 0,
          yPercent: 25,
          duration: 0.2,
          ease: 'expo.out',
        })
        .fromTo(el.querySelectorAll('.overlay'), {
          autoAlpha: 1,
        }, {
          autoAlpha: 0,
          duration: 0.4,
          ease: 'power0.out',
        }, 0)
        
        .to(el, {
          duration: 0,
          onComplete: done
        })
    },
  },
  mounted() {
    document.body.addEventListener('keyup', e => {
      if (e.keyCode === 27) {
        this.$emit('close-modal')
      }
    })


    let query = window.matchMedia("(max-width: 768px)")
    let directionX
    let directionY

    if (query.matches) {
      directionX = "0"
      directionY = "100"
    } else {
      directionX = "100"
      directionY = "0"
    }

    this.gsap.timeline()

      .from(this.$refs.opA, {
        scaleX: 0,
        transformOrigin: 'left top',
        duration: 0.8,
        ease: 'expo.out',
      }, 0)
      .from(this.$refs.opB, {
        scaleX: 0,
        transformOrigin: 'right top',
        duration: 0.8,
        ease: 'expo.out',
      }, 0)
      .from(this.$refs.logotype, {
        y: '150%',
        duration: 0.8,
        ease: 'expo.out',
      }, 0)

      .set(this.$refs.overlay, {
        autoAlpha: 1,
      })
      .set(this.$refs.modal, {
        autoAlpha: 1,
      })

      .to(this.$refs.logotype, {
        y: '-150%',
        duration: 0.8,
        ease: 'expo.in',
      }, 0.8)
      .to(this.$refs.opA, {
        scaleX: 0,
        transformOrigin: 'right top',
        duration: 0.8,
        ease: 'expo.in',
      }, '-=0.8')
      .to(this.$refs.opB, {
        scaleX: 0,
        transformOrigin: 'left top',
        duration: 0.8,
        ease: 'expo.in',
      }, '-=0.8')

      .from(this.$refs.fCont, {
        y: 100,
        duration: 1.2,
        ease: 'expo.out',
      }, '-=0.4')
      .from(this.$refs.aCont, {
        y: 50,
        duration: 1.2,
        ease: 'expo.out',
      }, '-=1.2')
      .from(this.$refs.aside, {
        xPercent: directionX,
        yPercent: directionY,
        duration: 1.2,
        ease: 'expo.out',
      }, '-=1.2')
      .set(this.$refs.last, { scale: 0, })
  }
}
</script>
