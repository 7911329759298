<template>
  <div>
    <header class="header" ref="logoHeader" :class="{hover : logoHover}">
      <div class="bg"></div>
      <div class="inner">
        <a href="" @mouseover="isMobile ? null : mouseEnter()" @mouseleave="isMobile ? null : mouseLeave()">
          <div class="logo-a" ref="logoA">
            <div class="circle a" ref="crclA">
              <img class="" src="@/assets/i/logo-circle.svg" alt="xe" />
            </div>
            <div class="circle b" ref="crclB">
              <img class="" src="@/assets/i/logo-circle.svg" alt="xe" />
            </div>
            <div class="circle c" ref="crclC">
              <img class="" src="@/assets/i/logo-circle.svg" alt="xe" />
            </div>
            <div class="sign" ref="sign">
              <img class="" src="@/assets/i/logo-sign.svg" alt="xe" />
            </div>
            <img class="r" ref="ra" src="@/assets/i/r.svg" alt="xe" />
          </div>
          <div class="logo-b" ref="logoB">
            <div class="type">
              <img class="" ref="typeB" src="@/assets/i/type-color.svg" alt="xe" />
              <img class="r" ref="rb" src="@/assets/i/r.svg" alt="xe" />
              <label class="small" ref="descB">and does it well</label>
            </div>
          </div>
        </a>
      </div>
    </header>

    <section class="page-head" ref="pageHeader">
      <div class="inner">
        <div class="cta">
          <div class="bttn" @click="$emit('open-modal')">
            <div>Request a demo</div>
          </div>
        </div>
        <div class="email">
          <div class="desktop">team@xe.works</div>
        </div>
        <div class="scroll" data-scroll data-scroll-speed="-8" data-scroll-position="top" data-scroll-offset="0,-1200">
          <div class="circle"></div>
          <img src="@/assets/i/arrow-down.svg" alt="xe">
          <label class="small">Scroll down</label>
        </div>
      </div>
    </section>

    <section class="imagine">
      <div class="head">
        <div class="inner">
          <div class="title">
            <h1>
              <div class="arrows" ref="mTitleArrows">
                <img src="@/assets/i/arrows-right.svg" alt="xe">
              </div>
              <div class="line a" ref="mTitleA">Imagine</div>
              <div class="line b" ref="mTitleB">
                <span>the</span>
                <div class="symbols">
                  <h5>Ad<br/>exchange</h5>
                  <div class="sign-anim">
                    <div class="sign-anim-inner" >
                      <div class="sign sign-a">
                      </div>
                      <div class="sign sign-b"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="line c" ref="mTitleC"><span>solution</span><label>Scroll down</label></div>
            </h1>
          </div>
        </div>
      </div>
      <div class="f-rows">
        <div class="inner">
          <div class="features">
            <div class="i a">
              <div class="tri">…</div>
              <div class="t-anim" data-scroll>where you get the final word but receive already well-calculated and analyzed data.</div>
            </div>
            <div class="i b">
              <div class="tri">…</div>
              <div class="t-anim" data-scroll>which is designed to make your ad spends smart and efficient.</div>
            </div>
            <div class="i c">
              <div class="tri">…</div>
              <div class="t-anim" data-scroll>which enables you to control and enhance your earnings.</div>
            </div>
          </div>
        </div>
        <div class="features-graphics">
          <div class="i a" data-scroll data-scroll-speed="0.5">
            <img class="a" src="@/assets/i/features/f1-1.svg" alt="xe">
            <img class="b" src="@/assets/i/features/f1-2.svg" alt="xe">
            <img class="c" src="@/assets/i/features/f1-3.svg" alt="xe">
            <div class="tt">
              <h5>F. 01</h5>
              <span class="small">Data</span>
            </div>
          </div>
          <div class="i b" data-scroll data-scroll-speed="1">
            <img class="a" src="@/assets/i/features/f2-1.svg" alt="xe">
            <img class="b" src="@/assets/i/features/f2-2.svg" alt="xe">
            <img class="c" src="@/assets/i/features/f2-3.svg" alt="xe">
            <div class="tt">
              <h5>F. 02</h5>
              <span class="small">Ad</span>
            </div>
          </div>
          <div class="i c" data-scroll data-scroll-speed="0.75">
            <img class="a" src="@/assets/i/features/f3-1.svg" alt="xe">
            <img class="b" src="@/assets/i/features/f3-2.svg" alt="xe">
            <img class="c" src="@/assets/i/features/f3-3.svg" alt="xe">
            <div class="tt">
              <h5>F. 03</h5>
              <span class="small">Earnings</span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="marquee">
      <div class="wrap">
        <div class="move" aria-hidden="true" ref="marqueeLine">
          <Marquee />
          <Marquee />
          <Marquee />
          <Marquee />
          <Marquee />
          <Marquee />
          <Marquee />
          <Marquee />
        </div>
      </div>
      <div class="agree">
        <div class="we">We agree</div>
        <div class="all h2">100%</div>
      </div>
    </section>

    <div class="bg-main">

      <section class="works">
        <div class="aside">
          <div class="inner">
            <h2>
              <div class="ofh">
                <div class="t-anim" data-scroll>Xe.Works</div>
              </div>
              <div class="ofh">
                <div class="t-anim" data-scroll>and does</div>
              </div>
              <div class="ofh">
                <div class="t-anim" data-scroll>it well</div>
              </div>
            </h2>
          </div>
        </div>
        <div class="wrap">
          <div class="tt t-anim" data-scroll>A perfect match for your programmatic buying and selling activities in RTB.</div>

          <div class="features">
            <div class="i a">
              <div class="circle t-anim" data-scroll></div>
              <div class="desc t-anim" data-scroll>Effortless launch</div>
            </div>
            <div class="i b">
              <div class="circle t-anim" data-scroll></div>
              <div class="desc t-anim" data-scroll>Ease in everyday management</div>
            </div>
            <div class="i c">
              <div class="circle t-anim" data-scroll></div>
              <div class="desc t-anim" data-scroll>Best<br/>value-for-money offer&nbsp;on the&nbsp;market</div>
            </div>
          </div>
        </div>
      </section>

      <section class="connect" data-scroll data-scroll-call="testEvent2">
        <div class="bg" data-scroll></div>
        <div class="inner">
          <div class="logo-black" data-scroll data-scroll-speed="0.5">
            <div class="circle a">
              <img class="" src="@/assets/i/logo-circle.svg" alt="xe" />
            </div>
            <div class="circle b">
              <img class="" src="@/assets/i/logo-circle.svg" alt="xe" />
            </div>
            <div class="circle c">
              <img class="" src="@/assets/i/logo-circle.svg" alt="xe" />
            </div>
            <div class="sign">
              <img class="" src="@/assets/i/logo-sign-b.svg" alt="xe" />
            </div>
          </div>
          <div class="tt" data-scroll>
            <div class="left small ofh"><div>Now you and<br/>your partner</div></div>
            <div class="line"></div>
            <div class="right small ofh"><div>are finally on<br/>the same page</div></div>
          </div>
          <div class="dec-black">
            <div class="rel" data-scroll data-scroll-speed="-0.5" data-scroll-direction="horizontal" data-scroll-offset="-200,0%">
              <div class="circle"></div>
              <img src="@/assets/i/arrow-right.svg" alt="xe">
            </div>
          </div>
        </div>
      </section>

      <section class="benefits">
        <div class="aside">
          <div class="inner">
            <h2 data-scroll data-scroll-speed="-0.5">
              <div class="ofh xe">
                <div class="t-anim" data-scroll>Benefits</div>
              </div>
              <div class="ofh">
                <div class="t-anim" data-scroll>you get</div>
              </div>
            </h2>
          </div>
        </div>
        <div class="wrap">
          <div class="list">
            <div class="li" data-scroll>
              <div class="bg"></div>
              <div class="line"></div>
              <div class="big t-anim" data-scroll @click="toggle">
                <span >Neat Interface</span><div class="icon"></div>
              </div>
              <div class="p t-anim" data-scroll>Marketplace has an easy-to-use interface to make your media trade running quick and easy. Friendly dashboard will help to keep track of current performance and be ready for optimizations.</div>
            </div>
            <div class="li" data-scroll>
              <div class="bg"></div>
              <div class="line"></div>
              <div class="big t-anim" data-scroll @click="toggle">
                <span>Deep Analytics</span><div class="icon"></div>
              </div>
              <div class="p t-anim" data-scroll>Access accurate and real-time reports with all preferred metrics and dimensions you can set up in a single dashboard. Have full control of traffic sales and make adjustments to your media trading activities whenever needed.</div>
            </div>
            <div class="li" data-scroll>
              <div class="bg"></div>
              <div class="line"></div>
              <div class="big t-anim" data-scroll @click="toggle"><span>Customizable Targeting</span><div class="icon"></div></div>
              <div class="p t-anim" data-scroll>Create, modify, and effectively run ad performance with extensive targeting capabilities. Set up geo, device, platform, and many more. Make your ads deliver to the right audience, in the relevant format, and on the right device.</div>
            </div>
            <div class="li" data-scroll>
              <div class="bg"></div>
              <div class="line"></div>
              <div class="big t-anim" data-scroll @click="toggle"><span>Bidding Optimization</span><div class="icon"></div></div>
              <div class="p t-anim" data-scroll>Automated bidding optimization enables high profits on your media trading. ML algorithms learn on every impression to find out all inventory trends that work best in your case. Based on that data, the system selects only relevant traffic to get the most of it.</div>
            </div>
            <div class="li" data-scroll>
              <div class="bg"></div>
              <div class="line"></div>
              <div class="big t-anim" data-scroll @click="toggle"><span>Dedicated and superb Team</span><div class="icon"></div></div>
              <div class="p t-anim" data-scroll>Be sure that Xe tech team is always there for you for smooth and non-stop platform functioning.</div>
            </div>
          </div>
        </div>
        <div class="cta">
          <div class="circle"></div>
          <div class="center"><span class="small">Request a demo</span></div>
          <div class="even-more">
            <div class="rel">
              <div class="circle"></div>
              <div class="small">And</div>
            </div>
            <div class="small">even<br/>more…</div>
          </div>
          <div class="overlay" @click="$emit('open-modal')"></div>
        </div>
      </section>

    </div>

    <div class="bg-white">

      <section class="easy">
        <div class="title h3 t-anim" data-scroll>Using our product is as easy as riding<span><img src="@/assets/i/easy/bicycle.svg" alt="xe"></span>a&nbsp;bike</div>
        <div class="graphics">
          <div class="e" data-scroll data-scroll-speed="1"><img src="@/assets/i/easy/aside.svg" alt="xe"></div>
          <div class="ac" data-scroll data-scroll-speed="0.5">
            <div class="a"><img src="@/assets/i/easy/A.svg" alt="xe"></div>
            <div class="c"><img src="@/assets/i/easy/C.svg" alt="xe"></div>
          </div>
          <div class="bd" data-scroll data-scroll-speed="-0.5">
            <div class="b"><img src="@/assets/i/easy/B.svg" alt="xe"></div>
            <div class="d"><img src="@/assets/i/easy/D.svg" alt="xe"></div>
          </div>
        </div>
      </section>

      <section class="solution">
        <div class="inner">
          <div class="title">
            <div class="h2">
              <div class="line a ofh"><div class="h-anim" data-scroll>Need</div></div>
              <div class="line b ofh">
                <div class="article">
                  <div class="circle sc-anim" data-scroll></div>
                  <div class="label"><label class="t-anim" data-scroll>a</label></div>
                </div>
                <div class="l h-anim" data-scroll>customized</div>
              </div>
              <div class="line c ofh"><div class="h-anim" data-scroll>solution?</div></div>
            </div>
          </div>
        </div>
        <div class="tt t-anim" data-scroll>Be sure that Xe tech team is always there for you for smooth and non-stop platform functioning.</div>
        <div class="logo r-anim" data-scroll>
          <img src="@/assets/i/logotype-xe-gray.svg" alt="xe">
        </div>
      </section>

      <section class="integration">
        <div class="aside">
          <div class="inner">
            <h2>
              <div class="ofh">
                <div class="t-anim" data-scroll>With</div>
              </div>
              <div class="ofh">
                <div class="t-anim" data-scroll>integration</div>
              </div>
              <div class="ofh">
                <div class="t-anim" data-scroll>you get</div>
              </div>
            </h2>
          </div>
        </div>
        <div class="wrap">
          <div class="bg" data-scroll></div>
          <div class="list">
            <div class="li"><div class="sc-anim" data-scroll></div><p class="t-anim" data-scroll>Painless, quick set up within a week.</p></div>
            <div class="li"><div class="sc-anim" data-scroll></div><p class="t-anim" data-scroll>20,000 QPS to go right on your first day.</p></div>
            <div class="li"><div class="sc-anim" data-scroll></div><p class="t-anim" data-scroll>Stable data centres for your steady growth and security.</p></div>
            <div class="li"><div class="sc-anim" data-scroll></div><p class="t-anim" data-scroll>Instant and constant support of Xe tech team.</p></div>
            <div class="li"><div class="sc-anim" data-scroll></div><p class="t-anim" data-scroll>Smart and clear system allows you to adjust the settings according to your latest needs and demands.</p></div>
            <div class="li"><div class="sc-anim" data-scroll></div><p class="t-anim" data-scroll>High intuitive, straightforward interface to onboard and adapt smoothly. You don’t need to have a full-packed tech team to make it work.</p></div>
          </div>    
        </div>
      </section>

    </div>

    <footer class="footer">
      <div class="inner">
        <div class="title">
          <div class="tt">
            <h5>B. 08</h5>
            <span class="small">Fin</span>
          </div>
          <h3>On top of this a nice surpri<span>z</span>e</h3>
        </div>
        <div class="message">
          <div class="left">It’s a working and competitive solution that does not cost all the money in the world, in fact, it is currently one of the best value-for-money offers on the market.</div>
          <div class="right">Spend wisely, earn effortlessly :)</div>
        </div>
        <div class="xe-cta">
          <div class="logo-white">
            <div class="circle a">
              <img class="" src="@/assets/i/logo-circle-w.svg" alt="xe" />
            </div>
            <div class="sign">
              <img class="" src="@/assets/i/logo-sign-w.svg" alt="xe" />
            </div>
            <img class="r" src="@/assets/i/r-w.svg" alt="xe" />
          </div>
          <div class="right">
            <div class="socials">
              &nbsp;
              <a href="https://www.facebook.com/Xe.works/" target="_blank">
                <img class="" src="@/assets/i/fb.svg" alt="xe" />
              </a>
              <a href="https://www.linkedin.com/company/xe-works/" target="_blank">
                <img class="" src="@/assets/i/in.svg" alt="xe" />
              </a>
            </div>
            <div class="cta">
              <div class="bttn" @click="$emit('open-modal')">
                <div>Request a demo</div>
              </div>
            </div>
            <div>&nbsp;</div>
          </div>
        </div>
        <div class="additional">
          <div class="copy small">@2021</div>
          <div class="email">
            <label>Drop us a line</label>
            <div class="desktop">team@xe.works</div>
            <a class="mobile" href="mailto:team@xe.works">team@xe.works</a>
          </div>
        </div>

        <div class="by">
          <div class="tt">
            <div class="left">Website by</div>
            <div class="line"></div>
            <div class="right"><a href="https://fiddle.digital" target="_blank">Fiddle.Digital</a></div>
          </div>
        </div>
        
      </div>
    </footer>

  </div>
</template>

<script>
import Marquee from '@/components/Marquee.vue'

export default {
  name: 'Page',
  components: {
    Marquee,
  },
  data () {
    return {
      isMobile: false,
      logoHover: false,
    }
  },
  methods: {

    mq() {
      this.isMobile = window.matchMedia('(max-width: 768px)').matches
    },

    mouseEnter()  {
      this.logoHover = !this.logoHover

      this.gsap.timeline()
        .to(this.$refs.crclC, {
          clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
          duration: 0.8,
          ease: 'expo.out',
        })
        .to(this.$refs.crclB, {
          clipPath: 'polygon(0% 67%, 100% 67%, 100% 67%, 0% 67%)',
          duration: 0.6,
          ease: 'expo.out',
        }, 0)
        .to(this.$refs.crclA, {
          clipPath: 'polygon(0% 33%, 100% 33%, 100% 33%, 0% 33%)',
          duration: 0.4,
          ease: 'expo.out',
        }, 0)
        .to(this.$refs.sign, {
          autoAlpha: 0,
          scale: 0.8,
          duration: 1.6,
          ease: 'expo.out',
        }, 0)
        .to(this.$refs.ra, {
          autoAlpha: 0,
          scale: 0.25,
          duration: 0.8,
          ease: 'expo.out',
        }, 0)

        .to(this.$refs.typeB, {
          autoAlpha: 1,
          yPercent: 0,
          duration: 0.4,
          ease: 'expo.out'
        }, 0)
        .to(this.$refs.rb, {
          autoAlpha: 1,
          scale: 1,
          duration: 0.8,
          ease: 'expo.out'
        }, 0)
        .to(this.$refs.descB, {
          autoAlpha: 1,
          yPercent: 0,
          duration: 0.4,
          ease: 'expo.out'
        }, 0)
    },
    mouseLeave() {
      this.logoHover = !this.logoHover

      this.gsap.timeline()
        .to(this.$refs.crclC, {
          clipPath: 'polygon(0% 67%, 100% 67%, 100% 100%, 0% 100%)',
          duration: 0.8,
          ease: 'expo.out',
        },0)
        .to(this.$refs.crclB, {
          clipPath: 'polygon(0% 33%, 100% 33%, 100% 67%, 0% 67%)',
          duration: 0.6,
          ease: 'expo.out',
        }, 0)
        .to(this.$refs.crclA, {
          clipPath: 'polygon(0% 0%, 100% 0%, 100% 33%, 0% 33%)',
          duration: 0.4,
          ease: 'expo.out',
        }, 0)
        .to(this.$refs.sign, {
          autoAlpha: 1,
          scale: 1,
          duration: 1.6,
          ease: 'expo.out',
        }, 0)
        .to(this.$refs.ra, {
          autoAlpha: 1,
          scale: 1,
          duration: 0.8,
          ease: 'expo.out',
        }, 0)

        .to(this.$refs.typeB, {
          autoAlpha: 0,
          yPercent: 50,
          duration: 0.4,
          ease: 'expo.out'
        }, 0)
        .to(this.$refs.rb, {
          autoAlpha: 0,
          scale: 0.25,
          duration: 0.8,
          ease: 'expo.out'
        }, 0)
        .to(this.$refs.descB, {
          autoAlpha: 0,
          yPercent: 50,
          duration: 0.4,
          ease: 'expo.out'
        }, 0)

    },

    toggle (e) {
      e.target.parentElement.classList.toggle('active')
    }
  },
  created () {
    window.addEventListener('resize', this.mq)
  },
  mounted () {

    this.gsap.set(this.$refs.crclA, { clipPath: 'polygon(0% 0%, 100% 0%, 100% 33%, 0% 33%)' })
    this.gsap.set(this.$refs.crclB, { clipPath: 'polygon(0% 33%, 100% 33%, 100% 67%, 0% 67%)' })
    this.gsap.set(this.$refs.crclC, { clipPath: 'polygon(0% 67%, 100% 67%, 100% 100%, 0% 100%)' })

    this.gsap.set(this.$refs.typeB, { autoAlpha: 0, yPercent: 50, })
    this.gsap.set(this.$refs.rb, { autoAlpha: 0, scale: 0.25, })
    this.gsap.set(this.$refs.descB, { autoAlpha: 0, yPercent: 50, })

    
    let logoHeader = this.$refs.logoHeader
    let pageHeader = this.$refs.pageHeader

    this.gsap.from(logoHeader, {
      yPercent: -25,
      duration: 1.6,
      delay: 0.4,
      ease: 'expo.out',
    })
    this.gsap.from(pageHeader, {
      yPercent: -25,
      duration: 2.4,
      delay: 0.4,
      ease: 'expo.out',
    })

    let mTitleArrows = this.$refs.mTitleArrows
    let mTitleA = this.$refs.mTitleA
    let mTitleB = this.$refs.mTitleB
    let mTitleC = this.$refs.mTitleC

    this.gsap.from(mTitleArrows, {
      yPercent: -25,
      duration: 2.4,
      delay: 0.4,
      ease: 'expo.out',
    })
    this.gsap.from(mTitleA, {
      yPercent: -75,
      duration: 2.4,
      delay: 0.4,
      ease: 'expo.out',
    })
    this.gsap.from(mTitleB, {
      yPercent: -50,
      duration: 2.4,
      delay: 0.4,
      ease: 'expo.out',
    })
    this.gsap.from(mTitleC, {
      yPercent: -25,
      duration: 2.4,
      delay: 0.4,
      ease: 'expo.out',
    })
    
    
    //———

    let marqueeLine = this.$refs.marqueeLine

    let marqIntersect = this.gsap.fromTo(marqueeLine, { xPercent: 25 }, {
      xPercent: -25,
      duration: 80,
      ease: 'none',
      repeat: -1,
    })
    marqIntersect.pause()

    let observeMarq = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if(entry.isIntersecting) {
          marqIntersect.play()
        }
        else {
          marqIntersect.pause()
        }
      })
    })
    observeMarq.observe(marqueeLine)
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.mq)
  }
}
</script>
