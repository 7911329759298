<template>
  <div class="text-content">
    <h3>Terms of use</h3>
    <div class="space"></div>
    <div class="space"></div>
    
    <p>Please read carefully these Terms and Conditions (“Terms”) before using the website <a href="https://xe.works">https://xe.works</a> (“Website”), as they affect your obligations and legal rights, including, but not limited to, waivers of rights, limitation of liability, and your indemnity to us.</p>
    <p>By registering an account on the Website or by using the Website as unregistered user, you agree to be bound by these Terms and any other rules or policies that are published from time to time on the Website. If you do not accept these Terms, please do not use the Website.</p>

    <div class="space"></div>
    <h4>1. Agreement</h4>
    <p>These Terms, together with Xeworks Privacy Policy, constitute a legally binding agreement between UAB Xeworks, a company incorporated under the laws of the Republic of Lithuania, Švitrigailos g. 11K-109, Vilnius, registration number 305700956, (“Xeworks”, “we”, “us”, “our”) and users, clients, visitors and others, who access the Website or use the Services (“Customer” “you”, “your”), collectively referred as to the “Parties” and severally as the “Party”.</p>
    <p>These Terms may be revised or updated at any time, so we encourage you to regularly check this page to make sure you are happy with all the changes. We may send you an additional email notification or publish information on the Website to inform you of any material amendments prior to the change becoming effective. Your continued use of the Website constitutes your acceptance of the updated Terms. If you do not accept the amendments, please stop using the Website.</p>
    <p>Xeworks reserves the right to change (temporary or permanently) the Website or any of the provided Services at any time and at its sole discretion with or without notice and you agree that Xeworks shall not be liable to you for such changes.</p>
    <p>Your personal information provided to Xeworks via the Website or arising in connection with the Services is protected according to Xeworks Privacy Policy.</p>

    <div class="space"></div>
    <h4>2. Services</h4>
    <p>Xeworks is an AdTech platform based on a Real Time Bidding (“RTB”) technology, using machine learning algorithms for maximizing advertising efficiency. We facilitate the purchase of media advertising inventory in RTB auctions, providing a specific software tools for our Customers, advertisers (“Advertiser”, “Demand Side Platform”, “DSP”) and publishers (“Publisher”, “Supply Side Platform”, “SSP”), to deliver targeted advertising materials (“Advertising Materials”) to end users who interact with the Publisher’s websites and/or mobile applications (“Digital Property”). Our AdTech solutions include Xeworks Marketplace (Xeworks AdExchange) and Xeworks Advertising Platform (“Services”).</p>
    

    <div class="space"></div>
    <h4>3. Registration</h4>
    <p>In order to get access to the Services, you shall register on our Website and create an account with the respective login and password. To sign up you shall enter your name, surname, company name, email address, password, or use available third-party networks, namely Google or Facebook. You hereby confirm that the information provided is valid, complete, and accurate.</p>
    <p>The Publisher is solely responsible for the accuracy and validity of the Digital Property characteristics provided on the Website Console, including, but not limited to the placement, platform, device, geolocation details.</p>
    <p>You hereby expressly consent that you are solely responsible for the use of your login and password for the account, for any registration data provided for account creation, and for any actions done during any use of your account. You agree to keep your login information and password private and to immediately notify us of any unauthorized account activity. You may be aware of and modify your login information respectively. You are solely responsible for any loss or damage you or we may suffer as a result of your failure to do so.</p>
    <p>Xeworks reserves the right to terminate your use of and registration with the Website or freeze any transactions on the Website at any time if you violate these Terms, at our sole discretion and without prior notice and without any liability or further obligation of any kind whatsoever to you or any other party, when we find such measures reasonable and/or necessary in a particular situation, without any refunds.</p>

    <div class="space"></div>
    <h4>4. The Customer’s License and Intellectual Property Rights</h4>
    <p>During the term hereof and subject to the Customer’s compliance with the Agreement, Xeworks provides the Customer with a limited, revocable, non-transferrable, non-exclusive, worldwide license:</p>

    <ul>
      <li>“Limited” (meaning that you can only use the Website for the purposes indicated in these Terms);</li>
      <li>“Revocable” (meaning that Xeworks can terminate this license);</li>
      <li>“Non-transferable” (meaning that the license is only for your benefit and you may not transfer or sub-license any of the rights you are granted to any other person);</li>
      <li>“Non-exclusive” (meaning that Xeworks can grant the same and similar licenses to other people as well);</li>
      <li>“Worldwide” (meaning that your use of the Website is not limited by territory, unless it is prohibited by the applicable law or the law of the country of your location).</li>
    </ul>
    <p>Xeworks exclusively owns all rights, title, and interest in the “Xeworks” name, “Xeworks” logo, patents, copyrights (including rights in derivative works), trademarks or service marks, logos and designs, trade secrets, domain names, and other intellectual property embodied by, or displayed on the Website.</p>
    <p>You shall never use any Xeworks logos or marks for commercial and public use without our express written permission. You shall not copy, reproduce, distribute, modify, adapt, create derivative works of, publicly display, publicly perform, republish, download, store, transmit or otherwise use any of the Xeworks logos or marks. You also shall not make any public statement or issue any press release related to the Services without our express written permission. There are no implied licenses under these Terms and other documents, and any rights not expressly granted to you hereunder are reserved by Xeworks.</p>
    <p>You shall never copy, reproduce, republish, post, display, encode, translate, transmit or distribute any content available on the Website in any way to any other computer, server, website or medium for publication or distribution or for any commercial enterprise, without our express written permission.</p>
    <p>The Services may be performed and/or developed on the basis of the open source software.</p>
    <p>Please note that the use of each open source software is subject to a separate license that is out of the scope of this Agreement. Xeworks does not make any representation or warranty with respect to any open source software that may be integrated with or accompany the Services. Xeworks hereby disclaims all liability to you or any third party related to any such open source software that may be integrated with or accompany the Services.</p>

    <div class="space"></div>
    <h4>5. Third-Party Content</h4>
    <p>The Website may contain links to other websites of third parties. Xeworks does not control, endorse, or adopt any third-party content and makes no representation or warranties of any kind regarding the third-party content, including but not limited to its accuracy or completeness.</p>
    <p>You acknowledge and agree that Xeworks is not responsible or liable in any manner for any third-party content and undertakes no responsibility to update or review any third-party content. You acknowledge that you use such third-party content at your own risk and you are solely responsible for reviewing, understanding and complying with the associated terms and conditions.</p>

    <div class="space"></div>
    <h4>6. Representations and Warranties</h4>
    <p>Mutual Representations and Warranties</p>
    <p>By using the Website and/or registering an account, each Party hereby represent, acknowledges and warrant, that:</p>
    <ul>
      <li>he/she is of an age of majority to enter into this Agreement (at least 18 years old) and comply with all other eligibility criteria and is fully able and legally competent to use the Website;</li>
      <li>he/she has full right, power, legal capacity and authority to enter into and by bound by the Terms and that its performance hereunder will fully comply with all applicable laws, rules and regulations;</li>
      <li>these Terms constitute a valid and binding obligation of the customer, enforceable in accordance with its terms.</li>
    </ul>
    <p>The Publisher’s Representations and Warranties</p>
    <p>By using the Website and/or registering an account, the Publisher hereby represent, acknowledges and warrant, that:</p>
    <ul>
      <li>he/she is the legal owner of the Digital Property and its components, an employee of the legal owner of the Digital Property, or he/she has the legal right, power and authority, confirmed by respective documents to enter into this Agreement on behalf of such legal owner and bind to these Terms;</li>
      <li>his/her performance under these Terms and/or the rights granted herein does not and will not conflict with or result in a breach, violation or default of any of the terms or conditions, contract or agreement, to which he/she is a party to, or nor will he/she become bound in the future to any such terms or conditions, contract or agreement;</li>
      <li>he/she will not use the Services for any illegal activity and he/she has never conceivably been, engaged in any fraudulent, deceptive and illegal activity, including but not limited to human trafficking; money laundering; terrorist financing, proliferation of weapons of mass destruction; drugs, narcotics; illegal gambling services; any services, marketing that violates intellectual property rights;</li>
      <li>he/she will not use any device, software or routine to interfere with the proper working of the Website, including, but not limited to launch or use of viruses, Trojan horses, any automated programs and scripts;</li>
      <li>he/she will not intentionally or unintentionally violate any requirements of any applicable local, state, national or international law;</li>
      <li>he/she will be liable for all costs, losses and expenses incurred by us by reason of an unauthorized use of your account and/or as a result of your acting fraudulently or failing to comply with these Terms.</li>
    </ul>
    <p>The Advertiser’s Representations and Warranties</p>
    <p>By using the Website and/or registering an account, the Advertiser hereby represent, acknowledges and warrant, that:</p>

    <ul>
      <li>the Advertising Materials does not and will not violate any intellectual property rights, data protection rights, or any other proprietary rights of any third party;</li>
      <li>The Advertising Materials does not and will not contain any Prohibited Content pursuant to this Agreement;</li>
      <li>he/she will not use any device, software or routine to interfere with the proper working of the Website, including, but not limited to launch or use of viruses, Trojan horses, any automated programs and scripts;</li>
      <li>he/she will not intentionally or unintentionally violate any requirements of any applicable local, state, national or international law;</li>
      <li>he/she will be liable for all costs, losses and expenses incurred by us by reason of an unauthorized use of your account and/or as a result of your acting fraudulently or failing to comply with these Terms.</li>
    </ul>
    <p>The DSP and SSP hereby agree that in case of breach of any representations and warranties under these Terms or the applicable laws, Xeworks reserves the right to terminate your account and take legal actions under the applicable law and these Terms at any time without notice.</p>

    <div class="space"></div>
    <h4>7. Prohibited Content</h4>
    <p>All and any Advertising Materials shall not:</p>
    <ul>
      <li>contain, promote or have links to any sexually explicit materials, obscene language, hate material, defamatory materials, materials promoting violence, the use of firearms or discrimination based on race, sex, religion, nationality, disability, sexual orientation, age, or family status, or any other materials deemed harmful to the reputation of Xeworks in the Xeworks sole discretion;</li>
      <li>contain information which is false, deceptive, misleading, unethical, defamatory, libelous, or threatening;</li>
      <li>contain hate material, nudity, pornography or highly offensive graphics, file-sharing content or language;</li>
      <li>promote any software piracy systems (warez, cracking, etc.), hacking, phreaking, emulators, ROM’s, or illegal MP3 activity;</li>
      <li>promote any illegal activities, deceptive practices, violations or infringements of the intellectual property rights of others;</li>
      <li>results in consumer fraud, product liability, or breach of contract to which the Customer is a party, or causes injury to any third party;</li>
      <li>promote content related to illicit drugs and drug paraphernalia, gambling or promotion of gambling, sales or promotion of weapons or ammunition;</li>
      <li>promote activities generally understood as internet abuse, including but not limited to, the sending of unsolicited bulk electronic mail or the use of spyware programs;</li>
      <li>be advertised or promoted through the use of unsolicited bulk email.</li>
    </ul>
    <p>Xeworks shall not be liable for and does not provide any representation or warranty regarding the Advertising Materials, the advertising campaign available on the Digital Property, its content and/or quality. Xeworks does not have any obligation to monitor the content of the Advertising Materials and/or the advertising campaign available on the Digital Property. In no way shall Xeworks be liable for accuracy, validity, completeness, appropriateness and/or legality of the above-mentioned content.</p>
    <p>In the event that any Advertising Materials, the advertising campaign available on the Digital Property in any manner violates the terms of this Agreement, we reserve the right to suspend provision of the Services at any time and without notice.</p>

    <div class="space"></div>
    <h4>8. Restrictions on Use</h4>
    <p>While using the Website and/or the Services you may not, and may not encourage, authorize, or assist others to:</p>
    <ul>
      <li>copy or duplicate the Website, in whole or in part;</li>
      <li>modify, adapt, improve, enhance, translate, or otherwise create derivative works based on the Website;</li>
      <li>reverse engineer, decompile, disassemble, or in any way access or attempt to access the source code, object code or underlying structure, ideas, or algorithms relevant to the Website, and/or extract any files from them;</li>
      <li>delete, alter or obscure, any copyright or other proprietary rights notice from any content available on the Website;</li>
      <li>modify, defeat, interfere with, or circumvent any feature of the Website, including any security or access control mechanism;</li>
      <li>attempt to probe, study or test the vulnerability of the Website or to breach any security or authentication measures, regardless of your motives and/or intent;</li>
      <li>attempt to damage, interfere with, or disrupt the Services to any customer, processor, host or network, including by submitting a virus, worm, or Trojan horse;</li>
      <li>use or launch any “robot”, “spider”, “page-scraper”, “deeplink”, or any other automated means to access, acquire, copy, or monitor any part of the Website;</li>
      <li>take any action that would impose a disproportionately large or unreasonable load on our network or other infrastructure, or detrimentally interfere with, intercept, or expropriate any system, data, or information;</li>
      <li>sell, copy, distribute, pledge, assign or otherwise transfer or encumber rights granted under these Terms or any right or ability to view, access, or use the Website and/or the Services;</li>
      <li>use the Website for creating a product, service or software that is, directly or indirectly, competitive with or in any way a substitute for any services or software owned by the Website;</li>
      <li>pretend that you are, or that you represent, someone else or impersonate any other individual or entity;</li>
      <li>use any information obtained from the Website in order to harass, abuse, discriminate, endanger or harm another person;</li>
      <li>use any contact information and e-mail addresses that can be found on the website to send any unsolicited commercial information.</li>
      <li>access or use the Website in a manner inconsistent with any applicable laws or regulations;</li>
      <li>infringe any Section of these Terms.</li>
    </ul>

    <div class="space"></div>
    <h4>9. Limitation of Liability</h4>
    <p>To the maximum extent permitted by the applicable law, we or our affiliates do not accept any liability for any damage or loss, including loss of business, revenue, or profits, or loss of or damage to data, equipment, or software (direct, indirect, punitive, actual, consequential, incidental, special, exemplary or otherwise), resulting from any use of, or inability to use, the Website and the Services or the material, information, software, facilities, services or content on this regardless of the basis, upon which the liability is claimed.</p>
    <p>If applicable law does not allow all or any part of the above limitation of liability to apply to you, the limitations will apply to you only to the extent permitted by applicable law. You understand and agree that it is your obligation to ensure compliance with any legislation relevant to your country of domicile concerning use of the Website, and that you should not accept any liability for any illegal or unauthorized use of the Website. You agree to be solely responsible for any applicable taxes imposed under your tax residency regulations.</p>
    <p>In no event shall our company, our directors, members, employees or agents be liable for any loss resulting from hacking, tampering, virus transmission or other unauthorized access or use of the Services, your account, or any information contained therein; for products not being available for use; for improper functionality, technical faults and downtime of the technical infrastructure.</p>

    <div class="space"></div>
    <h4>10. Indemnification</h4>
    <p>You hereby agree to indemnify Xeworks, any of its officers, directors, employees and agents and its affiliated and related entities from and against any claims, costs, losses, liabilities, damages, expenses and judgments of any and every kind arising out of, relating to, or incurred in connection with any claim, complaint, audit, inquiry, or other proceeding, that arises or relates to: (a) any actual or alleged breach of your representations, warranties, or obligations set forth in these Terms; (b) your wrongful or improper use of the Services; (c) any other party’s access or use of the Services with your account information; (d) arising out of a breach of any warranty, representation, or obligation hereunder.</p>
    <p>You shall not have any claim of any nature whatsoever against Xeworks for any failure to carry out any of our obligations under these Terms as a result of Force Majeure – causes beyond our control, including but not limited to any strike, lockout, shortage of labour or materials, delays in transport, in securing any permit, consent or approval required by Xeworks, for the supply of products, delay by any sub-contractor or supplier of ours accidents of any kind, riot, political or civil disturbances, the elements, by an act of state or government including regulatory action imposed or any other authority or any other cause whatsoever beyond our absolute and direct control.</p>

    <div class="space"></div>
    <h4>11. Disclaimer of Warranties</h4>
    <p>The Website and the Services are provided on an “as is” basis and without any warranties of any kind, either expressed or implied. You assume all responsibility and risk with respect to your use of the website and services. To the maximum extent permitted by the applicable law, Xeworks expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to, implied warranties of fitness for a particular purpose, merchantability, quality, and non-infringement.</p>
    <p>Xeworks does not guarantee that the Website and/or the Services will be available at all times, in all countries and/or all geographic locations, or at any given time, or that Xeworks will continue to offer the Services for any particular length of time (unless we expressly say otherwise as part of the Services).</p>
    <p>We do not guarantee continuous, uninterrupted or secure access to the Services and you acknowledge and agree that the Website may be interfered with by numerous factors outside of our control. Xeworks makes no representations, and expressly disclaims any warranties, of any kind, regarding the accuracy, completeness, reliability, appropriateness, and timeliness of any content available through the Website. Xeworks does not warrant that the results that may be obtained from the use of the Services will be accurate or reliable.</p>

    <div class="space"></div>
    <h4>12. Applicable Law and Disputes Resolution</h4>
    <p>These Terms and other relationships between you and UAB Xeworks shall be governed by the laws of the Republic of Lithuania.</p>
    <p>Any dispute, controversy or claim arising out of or relating to these Terms, or the breach thereof, shall first be resolved through good faith negotiations for not less than 30 (thirty) calendar days following written notification of such controversy or claim to the other party.</p>
    <p>You agree that for the purposes of the settlement of disputes between the parties, an e-mail correspondence with the authorized persons of Xeworks at: team@xe.works shall be the effective and binding method of communication.</p>
    <p>If the parties fail to agree in negotiations within 30 (thirty) calendar days from the date of their beginning, it shall be settled by the respective court of the Republic of Lithuania.</p>

    <div class="space"></div>
    <h4>13. Personal Data</h4>
    <p>We at Xeworks take your privacy very seriously. Please see our Privacy Policy that describes in more detail how, when, and why we collect personal data, how it may be used, to whom and under which conditions it may be disclosed, and your choices for managing information preferences. This document is a part of these Terms, so please make sure you read it.</p>

    <div class="space"></div>
    <h4>14. Termination</h4>
    <p>Notwithstanding anything contained herein, we reserve the right, without notice and at our sole discretion, to terminate these Terms or suspend your right to access the Website, including (but not limited to) in case of your breach of these Terms or if we believe that you have committed fraud, negligence or other misconduct.</p>
    <p>You may terminate these Terms without notice by discontinuing use of the Website. All rights granted to you under these Terms will immediately be revoked upon our termination of these Terms or our suspension of your access to the Website.</p>
    <p>In the event of any Force Majeure, breach of these Terms and all accompanying documents, or any other event that would make provision of given by us commercially unreasonable for the Website, we may, in our discretion and without liability to you, with or without prior notice, suspend your access to all or a portion of the Website and/or Services. We may terminate your access to the Website in our sole discretion, immediately and without prior notice, and delete all related information and files without liability to you, including, for instance, in the event that you breach any term of these Terms.</p>

    <div class="space"></div>
    <h4>15. Miscellaneous</h4>
    <p>Assignment. You may not assign any rights and/or licenses granted under these Terms. We reserve the right to assign our rights without restrictions to any party we may deem fit.</p>
    <p>Headings. Headings and subheadings of the sections are provided for convenience only and shall not be used to define, limit or describe the scope of such sections. All the sections shall survive any termination or expiration of the Terms.</p>
    <p>Severability. These Terms of Use shall supersede any other arrangements between the parties as well as all prior versions thereof. Should any provision, covenant and/or restriction of these Terms be void, unenforceable or legally invalid otherwise, it shall not affect, impair, or invalidate any other provision hereof, or these Terms as a whole.</p>
    <p>Waivers. Our failure to assert any right or provision in these Terms shall not constitute a waiver of such right or provision, and no waiver of any term shall be deemed a further or continuing waiver of such or other term.</p>

    <div class="space"></div>
    <h4>16. Contact us</h4>
    <p>If you have any questions about these Terms, please feel free to contact us at: team@xe.works.</p>
  </div>
</template>

<script>
export default {
  name: 'TOU',
}
</script>

<style lang="scss" scoped>
@import '../assets/css/var';

h4 {
  margin-bottom: 2rem;
  border-bottom: 1px solid $gray;
}
p {
  margin-bottom: 1.4rem;
}
.space {
  height: 2rem;
}
.sep {
  height: 1px;
  background: $gray;
}

ul {
  margin-top: 2rem;
  padding-left: 1.5rem;
  margin-bottom: 4rem;

  li {
    margin-bottom: 2rem;
    position: relative;

    &::before {
      content: "";
      background: $main;
      display: block; 
      width: 0.665em;
      height: 0.665rem;
      border-radius: 50%;
      position: absolute;
      left: -1.5rem;
      top: 0.35rem;
    }
    div {
      color: $main;
      margin-bottom: 0.5rem;
    }
    p {}
  }
}

</style>
